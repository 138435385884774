import React from "react"
import { Link } from "gatsby"
import { MdFileDownload } from "react-icons/md"
import { Heading, visuallyHiddenCss, Text } from "gatsby-interface"
import HRNumbers from "human-readable-numbers"
import GatsbyIcon from "../../../components/svg/gatsby-monogram"

const cardCss = theme => ({
  padding: theme.space[5],
  background: theme.colors.grey[5],
  borderRadius: theme.radii[3],
  color: theme.colors.grey[60],
  transition: theme.transitions.default,
  textDecoration: `none`,

  "&:hover": {
    background: theme.colors.white,
    boxShadow: theme.shadows.dialog,
  },
})

const cardHeadingCss = theme => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  fontSize: theme.fontSizes[0],
  marginBottom: theme.space[5],
})

const textCss = theme => ({ margin: 0, padding: 0, marginTop: theme.space[2] })

const headerContainerCss = { display: "flex", alignItems: "center" }
const headerDetailsCss = { display: "flex", alignItems: "center" }

const officialIconCss = theme => ({
  color: theme.colors.grey[50],
  display: "grid",
  alignItems: "center",
  gridTemplateColumns: "auto auto",
  gridGap: theme.space[2],
})

const downloadCss = theme => ({
  color: theme.colors.grey[50],
  display: "flex",
  alignItems: "center",
  marginLeft: theme.space[3],
})

export const DetailedPluginCard = ({
  img,
  title,
  pluginName,
  isOfficial,
  downloadCount,
  content,
}) => {
  return (
    <Link to={`/plugins/${pluginName}`} css={cardCss}>
      <div css={cardHeadingCss}>
        {img}
        <div css={headerContainerCss}>
          <div css={headerDetailsCss}>
            {isOfficial && (
              <div css={officialIconCss}>
                <GatsbyIcon /> Official
              </div>
            )}

            {downloadCount && (
              <React.Fragment>
                <span aria-hidden={true} css={downloadCss}>
                  <MdFileDownload /> {HRNumbers.toHumanString(downloadCount)}
                </span>

                <span css={visuallyHiddenCss}>
                  The package has been downloaded {downloadCount} times.
                </span>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

      <Heading
        as="h3"
        css={theme => ({
          fontFamily: theme.fonts.body,
          fontSize: theme.fontSizes[2],
        })}
      >
        {title}
      </Heading>

      <Text size="S" css={textCss}>
        {content}
      </Text>
    </Link>
  )
}
