import React from "react"

const wrapperCss = theme => ({
  padding: theme.space[7],
  [theme.mediaQueries.desktop]: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.space[10],
  },
})

const headerContainerCss = {
  position: "relative",
}

const titleCss = theme => ({
  marginBottom: theme.space[8],
})

const subtitleCss = theme => ({
  marginBottom: theme.space[5],
  maxWidth: "40rem",
})

const headingCTAsCss = theme => ({
  paddingBottom: theme.space[8],
})

export const pluginCtasCss = {
  display: "flex",
  flexDirection: "row",
  listStyleType: "none",
  margin: 0,
  padding: 0,
}

export const pluginCtaCss = theme => ({
  marginRight: theme.space[7],
})

export const navCss = theme => ({
  marginBottom: theme.space[8],
  position: "relative",
  zIndex: 2,
  background: theme.colors.white,
})

const contentCss = theme => ({
  maxWidth: `${theme.breakpoints[4]}`,
})

const footerCss = theme => ({
  margin: `${theme.space[10]} 0`,
})

export const PluginsLayout = ({
  Title,
  Subtitle,
  HeadingCTAs,
  Nav,
  Content,
  Footer,
}) => {
  return (
    <div css={wrapperCss}>
      <div css={headerContainerCss}>
        <div css={titleCss}>{Title}</div>
        <div css={subtitleCss}>{Subtitle}</div>
        <div css={headingCTAsCss}>{HeadingCTAs}</div>
      </div>
      <div css={navCss}>{Nav}</div>
      <div css={contentCss}>{Content}</div>
      <div css={footerCss}>{Footer}</div>
    </div>
  )
}
