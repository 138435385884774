import React from "react"

const wrapperCss = theme => ({
  marginBottom: theme.space[9],
})

const gridCss = theme => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridGap: theme.space[5],

  [theme.mediaQueries.tablet]: {
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
  },
})

const titleCss = theme => ({
  marginTop: theme.space[10],
  marginBottom: theme.space[7],
})

export const PluginSection = ({ Title, Plugins, ...props }) => {
  return (
    <div css={wrapperCss} {...props}>
      <div css={titleCss}>{Title}</div>
      <div css={gridCss}>{Plugins}</div>
    </div>
  )
}
