import * as images from "../../images/plugins"

export const topPlugins = [
  {
    name: "source-filesystem",
    pluginName: "gatsby-source-filesystem",
    image: images.filesystem,
    description:
      "Gatsby source plugin for building websites from local data. Markdown, JSON, images, YAM…",
  },
  {
    name: "Contentful",
    pluginName: "gatsby-source-contentful",
    image: images.contentful,
    description:
      "Gatsby source plugin for building websites using the Contentful CMS as a data source",
  },
  {
    name: "gatsby-plugin-image",
    pluginName: "gatsby-plugin-image",
    image: images.imagePlugin,
    description:
      "gatsby-image replacement adding a StaticImage component and updating GatsbyImage …",
  },
  {
    name: "plugin-sharp",
    pluginName: "gatsby-plugin-sharp",
    image: images.sharp,
    description:
      "Wrapper of the Sharp image manipulation library for Gatsby plugins",
  },
  {
    name: "MDX",
    pluginName: "gatsby-plugin-mdx",
    image: images.mdx,
    description: "MDX integration for Gatsby",
  },
  {
    name: "Remark",
    pluginName: "gatsby-transformer-remark",
    image: images.remark,
    description:
      "Gatsby transformer plugin for Markdown using the Remark library and ecosystem",
  },
  {
    name: "Sitemap",
    pluginName: "gatsby-plugin-sitemap",
    image: images.sitemap,
    description:
      "Gatsby plugin that automatically creates a sitemap for your site",
  },
  {
    name: "Airtable",
    pluginName: "gatsby-source-airtable",
    image: images.airtable,
    description:
      "Gatsby Airtable Source with Linked Nodes, Multiple Tables and File Nodes",
  },
  {
    name: "Amazon S3",
    pluginName: "gatsby-plugin-s3",
    image: images.s3,
    description: "Enables you to deploy your gatsby site to a S3 bucket",
  },
]
export const cms = [
  {
    name: "Contentful",
    pluginName: "gatsby-source-contentful",
    image: images.contentful,
  },
  {
    name: "WordPress",
    pluginName: "gatsby-source-wordpress",
    image: images.wordpress,
  },
  {
    name: "Drupal",
    pluginName: "gatsby-source-drupal",
    image: images.drupal,
  },
  {
    name: "Sanity",
    pluginName: "gatsby-source-sanity",
    image: images.sanity,
  },
  {
    name: "DatoCMS",
    pluginName: "gatsby-source-datocms",
    image: images.datocms,
  },
  {
    name: "Contentstack",
    pluginName: "gatsby-source-contentstack",
    image: images.contentstack,
  },
  {
    name: "Strapi",
    pluginName: "gatsby-source-strapi",
    image: images.strapi,
  },
  {
    name: "Kontent",
    pluginName: "@kentico/gatsby-source-kontent",
    image: images.kentico,
  },
  {
    name: "Netlify CMS",
    pluginName: "gatsby-plugin-netlify-cms",
    image: images.netlify,
  },
  {
    name: "Agility",
    pluginName: "@agility/gatsby-source-agilitycms",
    image: images.agilitycms,
  },
  {
    name: "Flotiq",
    pluginName: "gatsby-source-flotiq",
    image: images.flotiq,
  },
  {
    name: "Prismic",
    pluginName: "gatsby-source-prismic",
    image: images.prismic,
  },
  {
    name: "GraphCMS",
    pluginName: "gatsby-source-graphcms",
    image: images.graphcms,
  },
  {
    name: "ButterCMS",
    pluginName: "gatsby-source-buttercms",
    image: images.buttercms,
  },
  {
    name: "Storyblok",
    pluginName: "gatsby-source-storyblok",
    image: images.storyblok,
  },
]
export const css = [
  {
    name: "Emotion",
    pluginName: "gatsby-plugin-emotion",
    image: images.emotion,
  },
  {
    name: "Styled Components",
    pluginName: "gatsby-plugin-styled-components",
    image: images.styledComponents,
  },
  {
    name: "Sass",
    pluginName: "gatsby-plugin-sass",
    image: images.sass,
  },
  {
    name: "PostCSS",
    pluginName: "gatsby-plugin-postcss",
    image: images.postcss,
  },
  {
    name: "PurgeCSS",
    pluginName: "gatsby-plugin-purgecss",
    image: images.purgecss,
  },
  {
    name: "Less",
    pluginName: "gatsby-plugin-less",
    image: images.less,
  },
  {
    name: "MaterialUI",
    pluginName: "gatsby-plugin-material-ui",
    image: images.materialUI,
  },
  {
    name: "Linaria",
    pluginName: "gatsby-plugin-linaria",
    image: images.linaria,
  },
  {
    name: "Vanilla Extract",
    pluginName: "gatsby-plugin-vanilla-extract",
    image: images.vanillaExtract,
  },
]
export const analytics = [
  {
    name: "Google Analytics",
    pluginName: "gatsby-plugin-google-analytics",
    image: images.googleAnalytics,
  },
  {
    name: "Google Tag Manager",
    pluginName: "gatsby-plugin-google-tagmanager",
    image: images.googleTagManager,
  },
  {
    name: "Segment",
    pluginName: "gatsby-plugin-segment-js",
    image: images.segment,
  },
  {
    name: "Hotjar",
    pluginName: "gatsby-plugin-hotjar",
    image: images.hotjar,
  },
  {
    name: "Matomo",
    pluginName: "gatsby-plugin-matomo",
    image: images.matomo,
  },
  {
    name: "Mixpanel",
    pluginName: "gatsby-plugin-mixpanel",
    image: images.mixpanel,
  },
]
export const eCommerce = [
  {
    name: "Shopify",
    pluginName: "gatsby-source-shopify",
    image: images.shopify,
  },
  {
    name: "Snipcart",
    pluginName: "gatsby-plugin-snipcart-advanced",
    image: images.snipcart,
  },
  {
    name: "Bigcommerce",
    pluginName: "gatsby-source-bigcommerce",
    image: images.bigcommerce,
  },
  {
    name: "Stripe",
    pluginName: "gatsby-source-stripe",
    image: images.stripe,
  },
  {
    name: "theme-auth0",
    pluginName: "gatsby-theme-auth0-ts",
    image: images.auth0,
  },
  {
    name: "Okta",
    pluginName: "gatsby-plugin-okta",
    image: images.fallback,
  },
]

export const search = [
  {
    name: "Algolia",
    pluginName: "gatsby-plugin-algolia",
    image: images.algolia,
  },
  {
    name: "Elasticlunr",
    pluginName: "@gatsby-contrib/gatsby-plugin-elasticlunr-search",
    image: images.fallback,
  },
  {
    name: "plugin-local-search",
    pluginName: "gatsby-plugin-local-search",
    image: images.fallback,
  },
]
export const media = [
  {
    name: "imgix",
    pluginName: "@imgix/gatsby",
    image: images.imgix,
  },
  {
    name: "Cloudinary",
    pluginName: "gatsby-source-cloudinary",
    image: images.cloudinary,
  },
  {
    name: "Vimeo",
    pluginName: "gatsby-source-vimeo",
    image: images.vimeo,
  },
]
