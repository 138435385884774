import React from "react"
import { Link } from "gatsby"
import { MdFileDownload } from "react-icons/md"
import { Heading, visuallyHiddenCss } from "gatsby-interface"
import HRNumbers from "human-readable-numbers"
import GatsbyIcon from "../../../components/svg/gatsby-monogram"

const cardCss = theme => ({
  padding: theme.space[5],
  background: theme.colors.grey[5],
  borderRadius: theme.radii[3],
  color: theme.colors.grey[60],
  transition: theme.transitions.default,
  alignItems: "center",
  textDecoration: `none`,

  display: "flex",
  flexDirection: "row",

  "&:hover": {
    background: theme.colors.white,
    boxShadow: theme.shadows.dialog,
  },
})

const contentCss = theme => ({
  color: theme.colors.grey[50],
  display: "flex",
  alignItems: "center",
  fontSize: theme.fontSizes[0],
  marginTop: theme.space[2],
})

const downloadCss = theme => ({
  display: "flex",
  alignItems: "center",
  marginRight: theme.space[3],
})

export const ReducedPluginCard = ({
  img,
  title,
  pluginName,
  isOfficial,
  downloadCount,
}) => {
  return (
    <Link to={`/plugins/${pluginName}`} css={cardCss}>
      <div
        css={theme => ({
          marginRight: theme.space[5],
        })}
      >
        {img}
      </div>
      <div>
        <Heading
          as="h3"
          css={theme => ({
            fontFamily: theme.fonts.body,
            fontSize: theme.fontSizes[2],
          })}
        >
          {title}
        </Heading>

        <div css={contentCss}>
          {downloadCount && (
            <React.Fragment>
              <span aria-hidden={true} css={downloadCss}>
                <MdFileDownload /> {HRNumbers.toHumanString(downloadCount)}
              </span>

              <span css={visuallyHiddenCss}>
                The package has been downloaded {downloadCount} times.
              </span>
            </React.Fragment>
          )}
          {isOfficial && (
            <React.Fragment>
              <GatsbyIcon css={theme => ({ marginRight: theme.space[1] })} />{" "}
              Official
            </React.Fragment>
          )}
        </div>
      </div>
    </Link>
  )
}
