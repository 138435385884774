import React from "react"
import propTypes from "prop-types"
import { Heading, Text, Link, Spacer } from "gatsby-interface"
import { MdChevronRight } from "react-icons/md"
import Layout from "../../layout"
import SiteHead from "../../head"
import PageWithPluginSearchBar from "./components/page-with-plugin-searchbar"
import {
  PluginsLayout,
  pluginCtasCss,
  pluginCtaCss,
} from "./layouts/plugins-layout"
import { PluginSection } from "./components/plugin-section"
import { DetailedPluginCard } from "./components/detailed-plugin-card"
import { ReducedPluginCard } from "./components/reduced-plugin-card"
import {
  PluginSectionNav,
  PluginSectionNavItem,
} from "./components/plugin-section-nav"

import {
  topPlugins,
  cms,
  css,
  analytics,
  eCommerce,
  search,
  media,
} from "./plugins"
import { titleStyles } from "../../utils/styles"
import { graphql } from "gatsby"

const headingCss = theme => [
  titleStyles(theme),
  {
    position: "relative",
    zIndex: 2,

    span: {
      color: theme.colors.purple[50],
      display: "block",
    },
  },
]

const footerHeadingCss = theme => ({
  fontFamily: theme.fonts.body,
  fontSize: theme.fontSizes[9],
  fontWeight: theme.fontWeights.bold,
  letterSpacing: theme.letterSpacings.tight,
  marginBottom: theme.space[7],
  marginTop: theme.space[3],

  span: {
    display: "block",
  },
})

const getPackageByPluginName = (packages, pluginName) => {
  const metadata = packages.find(plugin => plugin.name === pluginName)

  if (!metadata) {
    console.warn(
      `Plugin metadata for ${pluginName} not found in sourced packages from npm`
    )
    return {}
  }

  return metadata
}

export function Head(props) {
  return (
    <SiteHead
      {...props}
      title="Gatsby Plugin Library | Search 3,000+ Plugins"
      description="The library for searching and exploring Gatsby's vast plugin ecosystem to implement Node.js packages using Gatsby APIs"
    />
  )
}

const PluginsPage = ({ location, data }) => {
  const packages = data?.allNpmPackage?.nodes || []

  return (
    <Layout pathname={location.pathname}>
      <Spacer size={10} />
      <PageWithPluginSearchBar location={location} isPluginsIndex={true}>
        <PluginsLayout
          Title={
            <Heading as="h1" css={headingCss}>
              Welcome to the <span>Gatsby Plugin Library</span>
            </Heading>
          }
          Subtitle={
            <Text size="XL">
              Add functionality and customize your Gatsby site or app with
              thousands of plugins built by our amazing developer community.
            </Text>
          }
          HeadingCTAs={
            <ul css={pluginCtasCss}>
              <li css={pluginCtaCss}>
                <Link to="/docs/plugins/" variant="SIMPLE">
                  Plugin Documentation <MdChevronRight />
                </Link>
              </li>
              <li css={pluginCtaCss}>
                <Link to="/docs/creating-plugins/" variant="SIMPLE">
                  Creating Plugins <MdChevronRight />
                </Link>
              </li>
            </ul>
          }
          Nav={
            <PluginSectionNav>
              <PluginSectionNavItem to="#top-plugins">
                Top Plugins
              </PluginSectionNavItem>
              <PluginSectionNavItem to="#cms">CMS</PluginSectionNavItem>
              <PluginSectionNavItem to="#css">{`CSS & UI`}</PluginSectionNavItem>
              <PluginSectionNavItem to="#analytics">
                Analytics
              </PluginSectionNavItem>
              <PluginSectionNavItem to="#ecommerce">{`E-commerce, Payment & Auth`}</PluginSectionNavItem>
              <PluginSectionNavItem to="#search">Search</PluginSectionNavItem>
            </PluginSectionNav>
          }
          Footer={
            <div>
              <Heading as="h2" css={footerHeadingCss}>
                Want to create your own <span>plugins and themes?</span>
              </Heading>
              <Text size="L">
                Check our <Link to="/docs">developer documentation</Link>, or
                join the community on our
                {` `}
                <a href="https://github.com/gatsbyjs/gatsby/discussions">
                  developer forums
                </a>
                .
              </Text>
            </div>
          }
          Content={
            <React.Fragment>
              <PluginSection
                id="top-plugins"
                Title={<Heading as="h2">Top Plugins</Heading>}
                Plugins={topPlugins.map((plugin, idx) => {
                  const pluginMetadata = getPackageByPluginName(
                    packages,
                    plugin.pluginName
                  )

                  return (
                    <DetailedPluginCard
                      key={`top-plugin-${idx}`}
                      img={plugin.image}
                      title={plugin.name}
                      pluginName={plugin.pluginName}
                      isOfficial={pluginMetadata?.fields?.official}
                      downloadCount={pluginMetadata?.downloadsLast30Days}
                      content={plugin.description}
                    />
                  )
                })}
              />

              <PluginSection
                id="cms"
                Title={<Heading as="h2">CMS</Heading>}
                Plugins={cms.map((plugin, idx) => {
                  const pluginMetadata = getPackageByPluginName(
                    packages,
                    plugin.pluginName
                  )

                  return (
                    <ReducedPluginCard
                      key={`cms-plugin-${idx}`}
                      img={plugin.image}
                      title={plugin.name}
                      pluginName={plugin.pluginName}
                      isOfficial={pluginMetadata?.fields?.official}
                      downloadCount={pluginMetadata?.downloadsLast30Days}
                    />
                  )
                })}
              />

              <PluginSection
                id="css"
                Title={<Heading as="h2">{`CSS & UI`}</Heading>}
                Plugins={css.map((plugin, idx) => {
                  const pluginMetadata = getPackageByPluginName(
                    packages,
                    plugin.pluginName
                  )

                  return (
                    <ReducedPluginCard
                      key={`css-plugin-${idx}`}
                      img={plugin.image}
                      title={plugin.name}
                      pluginName={plugin.pluginName}
                      isOfficial={pluginMetadata?.fields?.official}
                      downloadCount={pluginMetadata?.downloadsLast30Days}
                    />
                  )
                })}
              />

              <PluginSection
                id="analytics"
                Title={<Heading as="h2">Analytics</Heading>}
                Plugins={analytics.map((plugin, idx) => {
                  const pluginMetadata = getPackageByPluginName(
                    packages,
                    plugin.pluginName
                  )

                  return (
                    <ReducedPluginCard
                      key={`analytics-plugin-${idx}`}
                      img={plugin.image}
                      title={plugin.name}
                      pluginName={plugin.pluginName}
                      isOfficial={pluginMetadata?.fields?.official}
                      downloadCount={pluginMetadata?.downloadsLast30Days}
                    />
                  )
                })}
              />

              <PluginSection
                id="ecommerce"
                Title={
                  <Heading as="h2">{`E-commerce, Payment & Auth`}</Heading>
                }
                Plugins={eCommerce.map((plugin, idx) => {
                  const pluginMetadata = getPackageByPluginName(
                    packages,
                    plugin.pluginName
                  )

                  return (
                    <ReducedPluginCard
                      key={`ecommerce-plugin-${idx}`}
                      img={plugin.image}
                      title={plugin.name}
                      pluginName={plugin.pluginName}
                      isOfficial={pluginMetadata?.fields?.official}
                      downloadCount={pluginMetadata?.downloadsLast30Days}
                    />
                  )
                })}
              />

              <PluginSection
                id="search"
                Title={<Heading as="h2">Search</Heading>}
                Plugins={search.map((plugin, idx) => {
                  const pluginMetadata = getPackageByPluginName(
                    packages,
                    plugin.pluginName
                  )

                  return (
                    <ReducedPluginCard
                      key={`search-plugin-${idx}`}
                      img={plugin.image}
                      title={plugin.name}
                      pluginName={plugin.pluginName}
                      isOfficial={pluginMetadata?.fields?.official}
                      downloadCount={pluginMetadata?.downloadsLast30Days}
                    />
                  )
                })}
              />
              <PluginSection
                id="media"
                Title={<Heading as="h2">Media</Heading>}
                Plugins={media.map((plugin, idx) => {
                  const pluginMetadata = getPackageByPluginName(
                    packages,
                    plugin.pluginName
                  )

                  return (
                    <ReducedPluginCard
                      key={`media-plugin-${idx}`}
                      img={plugin.image}
                      title={plugin.name}
                      pluginName={plugin.pluginName}
                      isOfficial={pluginMetadata?.fields?.official}
                      downloadCount={pluginMetadata?.downloadsLast30Days}
                    />
                  )
                })}
              />
            </React.Fragment>
          }
        />
      </PageWithPluginSearchBar>
    </Layout>
  )
}

PluginsPage.propTypes = {
  data: propTypes.object.isRequired,
  location: propTypes.object.isRequired,
}

export default PluginsPage

export const query = graphql`
  {
    allNpmPackage {
      totalCount
      nodes {
        downloadsLast30Days
        name
        description
        fields {
          official
        }
      }
    }
  }
`
